import { Outlet } from "react-router-dom";
import Sidenav from "./Sidenav";
import DashboardNavbar from "./DashboardNavbar";
import ChangePassword from "../../widgets/modals/users/ChangePassword";
import { useState } from "react";


const Dashboard = (props) => {
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const handleOpenUpdatePassword = () => setOpenUpdatePassword((cur) => !cur);

  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <Sidenav />
      <div className="py-4 pr-4 lg:pl-0 pl-4 lg:ml-80">
        <DashboardNavbar handleOpen={handleOpenUpdatePassword} />
        <main>
          <Outlet />
        </main>
      </div>

      <ChangePassword open={openUpdatePassword} handleOpen={handleOpenUpdatePassword} />
    </div>
  );
}

export default Dashboard;
