import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { motion, useInView, useAnimation } from 'framer-motion'

import banner from '../../img/senthome/banner.png';
import moment from '../../img/senthome/moment.png';

import home from '../../img/senthome/home.png';
import parfum from '../../img/senthome/parfum.png';
import candles from '../../img/senthome/candles.png';
import { Link } from 'react-router-dom';

const SentHome = () => {
    const bannerControls = useAnimation()
    const refBanner = useRef(null);
    const isInViewBanner = useInView(refBanner);

    const textControls = useAnimation()
    const refText = useRef(null);
    const isInViewText = useInView(refText);

    const galleryControls = useAnimation()
    const refGallery = useRef(null);
    const isInViewGallery = useInView(refGallery);

    useEffect(() => {
        if (isInViewBanner) {
            bannerControls.start("visible")
        }

        if (isInViewText) {
            textControls.start("visible")
        }

        if (isInViewGallery) {
            galleryControls.start("visible")
        }
    }, [isInViewBanner, isInViewText, isInViewGallery])



    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='text-info'>

                <div ref={refBanner}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={bannerControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className='relative'
                    >
                        {/* <img src={banner} className="w-full h-screen object-cover sm:bg-center bg-left" /> */}
                        <div className='w-full h-screen sm:bg-center bg-[25%]' style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover' }}>
                            <div className='absolute inset-y-0 sm:right-0 right-0 sm:w-1/2 w-full flex justify-center items-center'>
                                <div className='hidden sm:flex flex-col items-center p-10 mt-20'>
                                    <Typography className='text-white sm:text-3xl text-xl font-secondary text-center font-medium tracking-widest leading-10'>
                                        VOTRE MAISON EST VOTRE SANCTUAIRE.
                                    </Typography>

                                    <Typography className=' text-white text-center sm:text-2xl text-xl font-secondary font-light mt-4'>
                                        <p className="tracking-wider leading-10">Découvrez l’essence de votre chez-vous avec nos parfums d’ambiance maison </p>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </motion.section>
                </div>

                <div ref={refText}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={textControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-20"
                    >
                        <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-6 relative">
                            <div className='px-6 md:px-6 py-6 col-span-1 md:col-span-2 md:w-2/3 place-self-center z-20'>
                                <Typography variant='lead' className='text-[#B9806B] font-secondary tracking-wider font-medium text-center mb-4'>
                                    CHAQUE MOMENT PASSÉ,
                                    EST UN TEMPS
                                    DE PLAISANCE
                                </Typography>
                                <Typography variant='paragraph' className='text-info text-justify my-6'>
                                    Un tiers de notre journée est passé
                                    à la maison, ce temps doit être
                                    rendu aussi relaxant et apaisant
                                    que possible. Complétez votre
                                    décoration avec les diffuseurs de
                                    senteurs exceptionnels et la large
                                    gamme de fragrances pour faire de
                                    vos maisons un havre de paix
                                </Typography>
                            </div>

                            <div className='col-span-1 md:col-span-3 z-20'>
                                <div className='w-full h-64 sm:h-full bg-cover bg-center' style={{ backgroundImage: `url(${moment})` }}></div>
                            </div>

                            <div className='sm:block hidden h-48 bg-secondary absolute w-full -bottom-1/3 z-10'></div>
                        </div>
                    </motion.section>
                </div>


                <div ref={refGallery}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={galleryControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-28 relative"
                    >
                        <div className='bg-[#B9806B]'>
                            <div className='container mx-auto flex flex-col items-center p-20 pt-32'>
                                <Typography className='text-white sm:text-3xl text-xl font-normal text-center font-secondary tracking-wider'>
                                    UNE TOUCHE PARFUMÉE PARTOUT
                                </Typography>

                                <Typography className='text-white'>
                                    Explorez nos solutions
                                </Typography>
                            </div>
                        </div>


                        <div className='container mx-auto -mt-10 mb-20'>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                                
                                <Link to='/diffuser' className='hover:scale-105 transition duration-500'>
                                    <img src={home} />
                                    <div className='flex justify-between items-center mt-2'>
                                        <hr className='w-full border-[#B9806B]'></hr>
                                        <Typography variant='lead' className='text-[#B9806B] font-bold p-2'>
                                            DIFFUSEURS
                                        </Typography>
                                        <hr className='w-full border-[#B9806B]'></hr>
                                    </div>
                                </Link>

                                <Link to='/parfum' className='hover:scale-105 transition duration-500'>
                                    <img src={parfum} />
                                    <div className='flex justify-between items-center mt-2'>
                                        <hr className='w-full border-[#B9806B]'></hr>
                                        <Typography variant='lead' className='text-[#B9806B] font-bold p-2'>
                                            PARFUMS
                                        </Typography>
                                        <hr className='w-full border-[#B9806B]'></hr>
                                    </div>
                                </Link>

                                <Link to='/candle-reed' className='hover:scale-105 transition duration-500'>
                                    <img src={candles} />
                                    <div className='flex justify-between items-center mt-2'>
                                        <hr className='w-full border-[#B9806B]'></hr>
                                        <Typography variant='lead' className='text-[#B9806B] font-bold p-2'>
                                            BOUGIES
                                        </Typography>
                                        <hr className='w-full border-[#B9806B]'></hr>
                                    </div>
                                </Link>

                            </div>
                        </div>

                    </motion.section>
                </div>

            </div>
        </div>
    )
}

export default SentHome