import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { API_LINK } from "../apiConfig";

export const AuthContext = createContext({
    isLoggedIn: false,
    authTokens: null,
    login: () => { },
    logout: () => { },
});



export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(() =>
        localStorage.getItem("isLoggedIn")
            ? localStorage.getItem("isLoggedIn")
            : false
    );

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );

    const [loading, setLoading] = useState(true);


    const login = (authTokens, remember) => {
        setIsLoggedIn(true);
        setAuthTokens(authTokens);
        if (remember) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("authTokens", JSON.stringify(authTokens));
        }
    }

    const logout = () => {
        setIsLoggedIn(false);
        setAuthTokens(null);
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("authTokens");
    }


    const refreshHandler = async () => {
        if (authTokens) {
            axios.post(`${API_LINK}/auth/refresh`, {
                refreshToken: authTokens.refreshToken
            }).then(response => {
                setAuthTokens(response.data);
                localStorage.setItem("authTokens", JSON.stringify(response.data));
            }).catch((error) => {
                alert(error);
                logout();
            })

            if (loading) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (authTokens && loading) {
            refreshHandler();
        } else if (!authTokens) {
            setLoading(false)
        }

        let interval = setInterval(() => {
            if (authTokens) {
                refreshHandler();
            }
        }, 82800000)

        return () => clearInterval(interval);
    }, [authTokens, loading])




    const value = {
        isLoggedIn: isLoggedIn,
        authTokens: authTokens,
        login: login,
        logout: logout,
    }

    return (
        <AuthContext.Provider value={value}>
            {loading ? null : children}
        </AuthContext.Provider>
    )
}