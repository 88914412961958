import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

import { Outlet } from 'react-router-dom';

const Layout = (props) => {
    return (
        <div className="overflow-hidden">
            <Header />
            <main>
                <Outlet />
            </main>
            <Sidebar />
            <Footer />
        </div>
    )
}

export default Layout