import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";

const StatisticsChart = ({ color, chart, title, description, footer }) => {
    return (
        <Card className="bg-secondary shadow-none text-primary">
            <CardHeader variant="gradient" className='bg-secondary' floated={false} shadow={false}>
                <Chart {...chart} />
            </CardHeader>
            <CardBody className="px-6 pt-0">
                <Typography variant="h6">
                    {title}
                </Typography>
                <Typography variant="small" className="font-normal">
                    {description}
                </Typography>
            </CardBody>
            {footer && (
                <CardFooter className="border-t border-primary px-6 py-5 text-white">
                    {footer}
                </CardFooter>
            )}
        </Card>
    )
}

export default StatisticsChart