import React, { useState, useEffect } from 'react';
import { FaArrowUp } from "react-icons/fa";


const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        className="fixed bottom-5 right-5 bg-primary text-white border-none rounded-full w-12 h-12 flex items-center justify-center shadow-lg cursor-pointer transition-transform hover:scale-105 z-50"
      >
        <FaArrowUp />
      </button>
    )
  );
};

export default ScrollToTopButton;
