import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    IconButton,
    Spinner,
} from "@material-tailwind/react";

import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

export const AddUpdateCategory = ({ open, handleOpen, setCategories, selectedCategory, setSelectedCategory }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [image, setImage] = useState(null);

    const [imageData, setImageData] = useState(null);


    useEffect(() => {
        if (open) {
            if (selectedCategory) {
                setName(selectedCategory.name || '');
            }
        }
    }, [open, selectedCategory]);


    const addCategoryHandler = () => {
        setLoading(true);
        const fromdata = new FormData();
        if (name && (image || selectedCategory?.imageUrl)) {
            fromdata.append('name', name);
            fromdata.append('image', image);

            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            if (selectedCategory) {
                // Update logic
                axios.patch(`${API_LINK}/categories/${selectedCategory?.id}`, fromdata, requestConfig).then(({ data }) => {
                    setCategories(categories => categories.map(category => category.id === data.id ? data : category));
                    closeModalHandler();
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                // Add logic
                axios.post(`${API_LINK}/categories`, fromdata, requestConfig).then(({ data }) => {
                    setCategories(categories => [...categories, data]);
                    closeModalHandler();
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            }

        } else {
            alert("Veuillez remplir tous les champs");
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setName('');
        setImage('');

        setSelectedCategory(null);
        setImageData(null);
    }


    const handleImageChange = (e) => {
        const newImage = e.target.files[0];
        setImage(newImage);

        if (newImage) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageData(e.target.result);
            };
            reader.readAsDataURL(newImage);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setImageData(null);
        if (selectedCategory) {
            setSelectedCategory({
                ...selectedCategory,
                imageUrl: null,
            });
        }
    };

    return (
        <>
            <Dialog
                size="md"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            {selectedCategory ? "Modifier la catégorie" : "Ajouter une catégorie"}
                        </Typography>

                        <div className="w-full">

                            {(!image && !selectedCategory?.imageUrl && !imageData) ? (<div className="flex items-center justify-center w-full">
                                <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                    <div className="h-full w-full text-center flex justify-center items-center">
                                        <PhotoIcon className="h-[120px] text-gray-200" />
                                    </div>
                                    <input type="file" className="hidden" onChange={handleImageChange} />
                                </label>
                            </div>
                            ) : (
                                <div className="flex justify-center items-center relative w-full h-full">

                                    <div className="absolute top-0 right-0">
                                        <IconButton
                                            variant="text"
                                            color="blue-gray"
                                            onClick={() => handleRemoveImage()}
                                        >
                                            <XMarkIcon strokeWidth={3} className="h-6 w-6" />
                                        </IconButton>
                                    </div>

                                    < img src={(selectedCategory?.imageUrl && !imageData) ? `${API_LINK}/${selectedCategory?.imageUrl}` : imageData} alt="category display" />

                                </div>
                            )}


                            <div className="mt-4">
                                <Input label="Nom de la catégorie" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>


                        </div>

                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-[#E1B6A5] shadow-none" onClick={() => addCategoryHandler()} fullWidth>
                            {selectedCategory ? "Modifier" : "Ajouter"}
                        </Button>

                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}