import React from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
} from "@material-tailwind/react";

import { BsCartCheck } from "react-icons/bs";


export const SuccessOrder = ({ open, handleOpen }) => {
    return (
        <Dialog open={open} handler={handleOpen} size='xs'>
            <DialogBody className="grid place-items-center gap-4 p-16">
                <BsCartCheck size={100} className='text-primary' />
                <Typography variant="h4" className='text-primary w-2/3 text-center'>
                    Votre commande a bien été envoyé !
                </Typography>
            </DialogBody>
        </Dialog>
    )
}
