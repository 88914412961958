import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
} from "@material-tailwind/react";

import { PhotoIcon } from "@heroicons/react/24/outline";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsTrash, BsX } from "react-icons/bs";

export const AddUpdateDeleteAdvertisement = ({ open, handleOpen }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [advertisement, setAdvertisement] = useState();


    const [image, setImage] = useState(null);
    const [imageData, setImageData] = useState(null);


    useEffect(() => {
        setLoading(true);
        if (open) {
            axios.get(`${API_LINK}/advertisements`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setAdvertisement(data);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [open]);


    const addAdvertisementHandler = () => {
        setLoading(true);
        const fromdata = new FormData();
        if ((image || advertisement?.imageUrl)) {
            fromdata.append('image', image);

            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            axios.post(`${API_LINK}/advertisements`, fromdata, requestConfig).then(({ data }) => {
                closeModalHandler();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });

        } else {
            alert("Veuillez ajouter une image");
        }
    }

    const deleteAdvertisementHandler = () => {
        setLoading(true);
        if ((advertisement?.imageUrl)) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            axios.delete(`${API_LINK}/advertisements/${advertisement?.id}`, requestConfig).then(({ data }) => {
                closeModalHandler();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setAdvertisement(null);
        setImageData(null);
    }


    const handleImageChange = (e) => {
        const newImage = e.target.files[0];
        setImage(newImage);

        if (newImage) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageData(e.target.result);
            };
            reader.readAsDataURL(newImage);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setImageData(null);
        if (advertisement) {
            setAdvertisement({
                ...advertisement,
                imageUrl: null,
            });
        }
    };

    return (
        <>
            <Dialog
                size="xs"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            {advertisement ? "Modifier l'annonce publicitaire" : "Ajouter une annonce publicitaire"}
                        </Typography>

                        <div className="gap-4 w-full">

                            {(!image && !advertisement?.imageUrl && !imageData) ? (<div className="flex items-center justify-center w-full">
                                <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                    <div className="h-full w-full text-center flex justify-center items-center">
                                        <PhotoIcon className="h-[120px] text-gray-200" />
                                    </div>
                                    <input type="file" className="hidden" onChange={handleImageChange} />
                                </label>
                            </div>
                            ) : (
                                <div className="flex justify-center items-center relative w-full h-full">

                                    <div className="absolute top-0 right-0 p-2">
                                        <button className="flex justify-center items-center text-white w-12 h-12 bg-[#E1B6A5] rounded-lg mb-2"
                                            onClick={() => handleRemoveImage()}
                                        >
                                            <BsX className="text-3xl" />
                                        </button>

                                        {advertisement && <button
                                            className="w-12 h-12 bg-red-400 flex justify-center items-center text-white rounded-lg"
                                            onClick={() => deleteAdvertisementHandler()}
                                        >
                                            <BsTrash className="text-xl" />
                                        </button>}
                                    </div>

                                    < img src={(advertisement?.imageUrl && !imageData) ? `${API_LINK}/${advertisement?.imageUrl}` : imageData} alt="advertisement display" className="rounded-lg" />

                                </div>
                            )}
                        </div>

                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-[#E1B6A5] shadow-none" onClick={() => addAdvertisementHandler()} fullWidth>
                            {advertisement ? "Modifier" : "Ajouter"}
                        </Button>

                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}