import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import YouTube from 'react-youtube';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style/ImageGallery.scss";

import "react-quill/dist/quill.core.css";

import { API_LINK } from "../../apiConfig";
import axios from "axios";
import { ShowVideo } from "../../widgets/modals/products/ShowVideo";
import Parfum from "./Parfum";

import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { Tooltip, Typography } from "@material-tailwind/react";
import Diffuser from "./Diffuser";
import { FaPlay } from "react-icons/fa";
import { SidebarContext } from "../../contexts/SidebarContext";



const ProductDetails = () => {
  // get the product id from url
  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const { setIsOpen } = useContext(SidebarContext);

  const [quantity, setQuantity] = useState(1);

  const [isActive, setIsActive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState();

  const [openShowVideo, setOpenShowVideo] = useState(false);
  const handleOpenShowVideo = () => setOpenShowVideo((cur) => !cur);

  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); // New state for toggling description


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  useEffect(() => {
    setLoading(true)
    axios.get(`${API_LINK}/products/${id}`).then(({ data }) => {
      setProduct(data);
      if (data?.video) {
        const videoId = getYouTubeVideoId(data?.video);
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
        setThumbnailUrl(thumbnailUrl);
      }
      setLoading(false);
    }).catch((error) => {
      console.log(error);
    })
  }, [id]);


  const scrollToContent = () => {
    window.scrollBy({
      top: window.innerHeight * 0.6, // Faites défiler la fenêtre de visualisation vers le bas de la moitié de sa hauteur
      behavior: "smooth" // Ajoutez un comportement de défilement fluide
    });
  };


  const getYouTubeVideoId = (url) => {
    const regExp =
      /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match && match[1];
  };


  // if loading
  if (loading || !product) {
    return (
      <section className="h-screen flex justify-center items-center">
        Loading...
      </section>
    );
  }

  const images = product.images.map(image => ({
    original: `${API_LINK}/${image}`,
    thumbnail: `${API_LINK}/${image}`,
  }));


  return (

    <div>
      <div
        className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
      ></div>

      <div className='sm:mt-[110px] mt-[96px]'>

        <div className='bg-secondary w-full flex justify-center rounded-none mt-5 p-5'>
          <div className='flex sm:flex-row flex-col items-center text-primary'>
            <NavLink to="/diffuser" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Diffuseurs
                </Typography>
              )}
            </NavLink>
            <NavLink to="/parfum" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Parfums
                </Typography>
              )}
            </NavLink>

            <NavLink to="/candle-reed" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Candles & Reed
                </Typography>
              )}
            </NavLink>

            <NavLink to="/promotion" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Promotions
                </Typography>
              )}
            </NavLink>
          </div>
        </div>

        <div className={`${product.category === 'diffuseur' ? 'sm:pt-20 pt-10' : product.category === 'parfum' ? 'sm:pt-20 pt-10' : 'sm:py-20 py-10'} container mx-auto`}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="flex flex-col justify-start items-center">
              <div className="sm:w-4/5 w-full mb-4">
                <ImageGallery
                  items={images}
                  showPlayButton={false}
                  showFullscreenButton={true}
                  showIndex={true}
                  originalWidth={200}
                />
              </div>
              {/* {product.video && <button onClick={() => handleOpenShowVideo()} className="flex justify-center mt-4 underline text-lg">
                Voir la video
              </button>} */}
              {/* {product.video && <YouTube opts={{ height: '200', width: '320' }} videoId={getYouTubeVideoId(product.video)} />} */}
              {product.video && <div className="relative sm:w-4/5 w-full h-full">
                <img src={thumbnailUrl} className="w-full" />
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
                <FaPlay onClick={() => handleOpenShowVideo()} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white hover:opacity-50 text-4xl" />
              </div>}
            </div>

            <div className="ql-editor">
              <Link to={`${product.category === 'diffuseur' ? '/diffuser' : product.category === 'parfum' ? '/parfum' : product.category === 'candle-reed' && '/candle-reed'}`}
                className="text-xl capitalize font-normal mx-auto lg:mx-0 hover:cursor-pointer">
                {product.category}
              </Link>
              <h1 className="text-3xl capitalize font-medium mb-5 max-w-[450px] mx-auto lg:mx-0">{product.name}{product.specification && ` - ${product.specification}`}</h1>
              {/* <p className="mb-8 text-info font-light">{product.description}</p> */}

              {product?.category === "diffuseur" && <div className="mb-5">
                <div className="flex flex-row items-center border-y py-4 ">
                  <div className="flex flex-col mr-6 text-info">
                    <h2 className="text-base font-medium">Couleur</h2>
                    <p className="text-sm">{product.color}</p>
                  </div>
                  {product?.products.map((prod) => (<Link to={`/product/${prod.id}`} className="mx-1">

                    <Tooltip className="" content={<h2>{prod.color}</h2>}>
                      <img src={`${API_LINK}/${prod.images[0]}`} className={`${product.id === prod.id && "border-2 border-secondary"} w-14`} alt={prod.id} />
                    </Tooltip>
                  </Link>))}
                </div>
              </div>}

              {product?.category === "parfum" && <div className="my-5">
                <div className="flex flex-row items-center">
                  {product?.products.map((prod) => (<Link to={`/product/${prod.id}`} className="mx-1">
                    <div className={`${product.id === prod.id ? "bg-[#d9cdc3]" : "bg-[#F7F4F0]"} px-2 py-1 text-info`}>
                      <p>{prod.specification}</p>
                    </div>
                  </Link>))}
                </div>
              </div>}

              {/* <div className="mb-8 text-info" dangerouslySetInnerHTML={{ __html: product.description }} /> */}
              {/* view ql-editor */}

              {/* la nouvelle partie */}

              {/* Desktop view: full description */}
              <div className="hidden md:block mb-8 text-info" dangerouslySetInnerHTML={{ __html: product.description }} />

              {/* Mobile view: truncated description */}
              <div className={`block md:hidden text-info overflow-hidden ${isDescriptionExpanded ? 'h-auto' : 'h-[6rem]'} transition-all`} dangerouslySetInnerHTML={{ __html: product.description }} />

              {/* Mobile buttons */}
              {!isDescriptionExpanded && (
                <button
                  className="block md:hidden text-primary font-medium mb-4"
                  onClick={() => setIsDescriptionExpanded(true)}
                >
                  Voir plus
                </button>
              )}
              {isDescriptionExpanded && (
                <button
                  className="block md:hidden text-primary font-medium mb-4"
                  onClick={() => setIsDescriptionExpanded(false)}
                >
                  Voir moins
                </button>
              )}

              {/* <div className="text-2xl text-primary font-medium mb-6">{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD</div> */}


              <div className="mb-6">
                {product.promotion ?
                  (
                    <div className="flex items-center">
                      {/* Promotion Price */}
                      <h2 className='text-2xl font-medium mr-2'>{product.promotion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD  </h2>
                      {/* Original Price with line-through */}
                      <h2 className="text-2xl font-medium text-red-500 line-through">{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <span className="font-normal">DZD</span></h2>
                    </div>
                  ) :
                  <h2 className="text-2xl text-primary font-medium">{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <span className="font-normal">DZD</span></h2>}
              </div>

              <div className="flex items-center">
                <div className="flex flex-1 max-w-[160px] items-center p-4 border text-primary font-medium mr-6">
                  <div className="h-full flex-1 flex justify-center items-center cursor-pointer" onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>
                    <IoMdRemove />
                  </div>
                  <div className="h-full flex justify-center items-center px-2">
                    {quantity}
                  </div>
                  <div className="h-full flex flex-1 justify-center items-center cursor-pointer" onClick={() => quantity < product.stockQuantity && setQuantity(quantity + 1)}>
                    <IoMdAdd />
                  </div>
                </div>
                <button onClick={() => { addToCart(product, product.id, quantity); setIsOpen(true); if (product.category === 'diffuseur') scrollToContent(); }} className='bg-secondary text-primary py-4 px-8 w-full flex-1'>Ajouter au panier</button>
              </div>

            </div>
          </div>
        </div>

        {product.category === 'diffuseur' && <Parfum />}
        {product.category === 'parfum' && <Diffuser />}

        <ShowVideo open={openShowVideo} handleOpen={handleOpenShowVideo} video={product.video} />

      </div>
    </div >
  );
};

export default ProductDetails;
