import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Typography } from '@material-tailwind/react'
import { PlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import axios from 'axios'

import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'

import CategoryCard from '../../components/dashboard/CategoryCard'
import { AddUpdateCategory } from '../../widgets/modals/categories/AddUpdateCategory'
import { DeleteCategory } from '../../widgets/modals/categories/DeleteCategory'


const Categories = () => {
    const { authTokens } = useContext(AuthContext);

    const [openAddCategory, setOpenAddCategory] = useState(false);
    const handleOpenAddCategory = () => setOpenAddCategory((cur) => !cur);

    const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
    const handleOpenDeleteCategory = () => setOpenDeleteCategory((cur) => !cur);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();

    const [search, setSearch] = useState();

    useEffect(() => {
        axios.get(`${API_LINK}/categories?search=${search}`, {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`
            }
        }).then(({ data }) => {
            setCategories(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [search])


    return (
        <div className="mt-12">
            <div className="flex justify-between flex-row items-center pb-4">
                <div></div>

                <div className='flex items-center'>

                    <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
                        <Input label='Recherche' onChange={(e) => setSearch(e.target.value)} />
                    </div>

                    <Button size="sm" className="bg-[#E1B6A5] capitalize text-base text-white flex items-center shadow-none" onClick={() => handleOpenAddCategory()}>
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Ajouter une catégorie
                    </Button>
                </div>


            </div>


            {categories.length !== 0 ? <div className="w-full flex flex-col">
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                    {categories.map((category) => (
                        <CategoryCard key={category.id} data={category} setSelectedCategory={setSelectedCategory} handleOpenAddCategory={handleOpenAddCategory} handleOpenDeleteCategory={handleOpenDeleteCategory} />
                    ))}
                </div>
            </div>
                : <div className="w-full">
                    <div className="flex flex-col justify-center items-center bg-primary p-6 rounded-2xl">
                        <ExclamationTriangleIcon className='text-white w-20 mb-4' />
                        <Typography variant="lead" color="white" className="">
                            Vous n'avez encore aucune catégorie !
                        </Typography>
                    </div>
                </div>}

            <AddUpdateCategory open={openAddCategory} handleOpen={handleOpenAddCategory} setCategories={setCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            <DeleteCategory open={openDeleteCategory} handleOpen={handleOpenDeleteCategory} setCategories={setCategories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
        </div>
    )
}

export default Categories