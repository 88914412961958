import React, { useRef, useState } from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper/modules";


// Import Swiper styles
import 'swiper/css';
import "swiper/css/thumbs"
import 'swiper/css/scrollbar';

import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';

import { IconButton, Typography } from '@material-tailwind/react';
import dataMarks from './dataMarks';

function Marks() {
    const swiperRef = useRef();
    const [mobile, setMobile] = useState(false);
    const changeSizeNav = () => {
        if (window.innerWidth > 640) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    }
    window.addEventListener("resize", changeSizeNav);

    return (
        <div className="mx-auto">
            <div className='flex flex-row items-center'>
                <IconButton className='p-10' variant="text" onClick={() => swiperRef.current.slidePrev()}>
                    <BiLeftArrow className='w-8 h-8 text-primary' />
                </IconButton>
                <Swiper
                    className='sm:w-10/12 w-2/3'
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    loop={true}
                    slidesPerView={mobile ? 1 : 5}
                    navigation
                    centeredSlides={false}
                    modules={[Navigation, Autoplay, Thumbs]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={20}
                >
                    {dataMarks.map((mark) => {
                        return <SwiperSlide key={mark.id}>
                            <img className={`mx-auto w-auto`} src={mark.image} alt="" />
                        </SwiperSlide>
                    })}
                </Swiper>

                <IconButton className='p-10' variant="text" onClick={() => swiperRef.current.slideNext()}>
                    <BiRightArrow className='w-8 h-8 text-primary' />
                </IconButton>
            </div>
        </div>
    );
}

export default Marks;
