import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion'

import { API_LINK } from '../../apiConfig';
import Product from '../../components/Product';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from '@material-tailwind/react';

import { BsSearch } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';



const Diffuser = () => {
    const location = useLocation();
    const navigate = useNavigate();


    const containerControls = useAnimation()
    const refContainer = useRef(null);
    const isInViewContainer = useInView(refContainer);

    useEffect(() => {
        if (isInViewContainer) {
            containerControls.start("visible")
        }
    }, [isInViewContainer])

    const [isActive, setIsActive] = useState(false);
    const [products, setProducts] = useState([]);


    const searchParams = new URLSearchParams(location.search);
    const typediffuser = searchParams.get('type');
    const specificationdiffuser = searchParams.get('specification')
    const [type, setType] = useState(typediffuser || undefined);
    const [specification, setSpecification] = useState(specificationdiffuser || undefined);


    const [sortOrder, setSortOrder] = useState();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    useEffect(() => {
        axios.get(`${API_LINK}/products/search?category=diffuseur&type=${type}&specification=${specification}&priceOrder=${sortOrder}`).then(({ data }) => {
            // const diffusers = data.sort((a, b) => a.id - b.id);
            setProducts(data);  
        }).catch((error) => {
            console.log(error);
        })
    }, [type, specification, sortOrder]);

    const handleTabSelect = (tabValue) => {
        navigate(`/diffuser?type=${tabValue}`);
        setType(tabValue);
        setSpecification('');
    };

    const handleSpecificationSelect = (specValue) => {
        if (type) {
            navigate(`/diffuser?type=${type}&specification=${specValue}`);
            setSpecification(specValue);
        }
    };


    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all `}
            ></div>


            <div className='sm:mt-[110px] mt-[96px] text-info'>

                {!location.pathname.startsWith("/product/") && <div className='bg-secondary w-full flex justify-center rounded-none mt-5 p-5'>
                    <div className='flex sm:flex-row flex-col items-center text-primary'>
                        <NavLink to="/diffuser" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Diffuseurs
                                </Typography>
                            )}
                        </NavLink>
                        <NavLink to="/parfum" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Parfums
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/candle-reed" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Candles & Reed
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/promotion" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Promotions
                                </Typography>
                            )}
                        </NavLink>
                    </div>
                </div>}

                <div ref={refContainer}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={containerControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="py-10"
                    >
                        <div className="container mx-auto">
                            <Typography variant='h3' className="mb-10 text-center">CHOISIR VOTRE DIFFUSEUR</Typography>

                            <Tabs value={type}>
                                <TabsHeader className='bg-white rounded-none p-0 sm:w-1/3 max-w-full mx-auto gap-4' indicatorProps={{ className: 'shadow-none rounded-none bg-primary text-secondary' }}   /* indicatorProps={{ className: 'shadow-none rounded-none bg-secondary text-primary' }} */>
                                    <Tab value={'business'} className={`${type !== 'business' ? 'bg-[#F7F4F0]' : 'text-white'} p-2`} onClick={() => handleTabSelect('business')}>
                                        BUSINESS
                                    </Tab>
                                    <Tab value={'home'} className={`${type !== 'home' ? 'bg-[#F7F4F0]' : 'text-white'} p-2`} onClick={() => handleTabSelect('home')}>
                                        HOME
                                    </Tab>
                                </TabsHeader>
                                <TabsBody>
                                    <TabPanel value='home'>
                                        <div className='w-full flex justify-between items-center'>
                                            <select value={specification} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => handleSpecificationSelect(e.target.value)}>
                                                <option value='' disabled selected>Filtrer</option>
                                                <option value='home'>Home</option>
                                                <option value='voiture'>Voiture</option>
                                                <option value='sanitaire'>Sanitaire</option>
                                            </select>
                                            <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                <option value='' disabled selected>Trier</option>
                                                <option value="asc">Du moins cher</option>
                                                <option value="desc">Du plus cher</option>
                                            </select>
                                        </div>
                                        {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                            {products.map((product) => {
                                                return (
                                                    <Product product={product} key={product.id} />
                                                );
                                            })}
                                        </div>) : (
                                            <div className='flex flex-col justify-center items-center text-info'>
                                                <BsSearch size={150} className='text-gray-200 m-5' />
                                                <Typography variant='h4' className='text-center'>
                                                    Nous n'avons trouvez aucun resultat pour votre recherche.
                                                </Typography>
                                            </div>)}
                                    </TabPanel>
                                    <TabPanel value='business'>
                                        <div className='w-full flex justify-between items-center'>
                                            <select value={specification} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => handleSpecificationSelect(e.target.value)}>
                                                <option value='' disabled selected>Filtrer</option>
                                                <option value='200ml'>200 ml</option>
                                                <option value='300ml'>300 ml</option>
                                                <option value='400ml'>400 ml</option>
                                            </select>
                                            <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                <option value='' disabled selected>Trier</option>
                                                <option value="asc">Du moins cher</option>
                                                <option value="desc">Du plus cher</option>
                                            </select>
                                        </div>

                                        {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                            {products.map((product) => {
                                                return (
                                                    <Product product={product} key={product.id} />
                                                );
                                            })}
                                        </div>) : (
                                            <div className='flex flex-col justify-center items-center text-info'>
                                                <BsSearch size={150} className='text-gray-200 m-5' />
                                                <Typography variant='h4' className='text-center'>
                                                    Nous n'avons trouvez aucun resultat pour votre recherche.
                                                </Typography>
                                            </div>)}
                                    </TabPanel>

                                    {type !== 'home' && type !== 'business' && (
                                        <TabPanel value={type}>
                                            <div className='w-full flex justify-between items-center'>
                                                <div></div>
                                                <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                    <option value='' disabled selected>Trier</option>
                                                    <option value="asc">Du moins cher</option>
                                                    <option value="desc">Du plus cher</option>
                                                </select>
                                            </div>
                                            {products.length !== 0 ? (<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                                {products.map((product) => {
                                                    return (
                                                        <Product product={product} key={product.id} />
                                                    );
                                                })}
                                            </div>) : (
                                                <div className='flex flex-col justify-center items-center text-info'>
                                                    <BsSearch size={150} className='text-gray-200 m-5' />
                                                    <Typography variant='h4' className='text-center'>
                                                        Nous n'avons trouvez aucun resultat pour votre recherche.
                                                    </Typography>
                                                </div>)}
                                        </TabPanel>
                                    )}
                                </TabsBody>
                            </Tabs>
                        </div>
                    </motion.section>
                </div>
            </div>
        </div>
    )
}

export default Diffuser