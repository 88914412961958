import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../contexts/AuthContext';
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Spinner,
} from "@material-tailwind/react";
import axios from 'axios';
import { API_LINK } from '../../../apiConfig';

const ChangePassword = ({ open, handleOpen }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');


    const updatePasswordHandler = () => {
        setLoading(true);
        if (oldPassword && newPassword && confirmNewPassword) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            if (newPassword === confirmNewPassword) {
                if (authTokens?.user) {
                    axios.patch(`${API_LINK}/users/${authTokens?.user.id}/change-password`, {
                        oldPassword,
                        newPassword
                    }, requestConfig).then(({ data }) => {
                        closeModalHandler();
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        setLoading(false);
                    });
                }

            } else {
                alert("Veuillez vérifier votre nouveau mot de passe");
            }

        } else {
            alert("Veuillez remplir tous les champs");
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setNewPassword('');
        setOldPassword('');
        setConfirmNewPassword('');
    }


    return (
        <>
            <Dialog
                size="xs"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Changer mot de passe
                        </Typography>
                        <div className="grid grid-cols-1 gap-4  w-full">
                            <Input label="Ancien mot de passe" type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                            <Input label="Nouveau mot de passe" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            <Input label="Confirmer nouveau mot de passe" type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                        </div>

                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-[#E1B6A5] shadow-none" onClick={() => updatePasswordHandler()} fullWidth>
                            Modifier
                        </Button>

                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    )
}

export default ChangePassword