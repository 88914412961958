import React from 'react'
import { Card, CardBody, CardFooter } from '@material-tailwind/react'
import { API_LINK } from '../../apiConfig'
import { BsPencil, BsTrash } from 'react-icons/bs'

const CategoryCard = ({ data, setSelectedCategory, handleOpenAddCategory, handleOpenDeleteCategory }) => {
    return (
        <Card shadow={false} className='bg-primary'>
            <CardBody className="border border-[#e4e4e4] relative overflow-hidden group transition rounded-t-lg m-0 p-0">
                <div className="w-full h-full flex justify-center items-center">
                    {/* image */}
                    <div className="w-full mx-auto flex justify-center items-center">
                        <img
                            className=" group-hover:scale-110 transition duration-300 rounded-t-lg"
                            src={`${API_LINK}/${data.imageUrl}`}
                            alt={data.name}
                        />
                    </div>
                </div>
                {/* buttons */}
                <div className="absolute top-6 -right-11 group-hover:right-5 p-2 flex flex-col justify-center items-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">

                    <button className="flex justify-center items-center text-white w-12 h-12 bg-[#E1B6A5] rounded-lg"
                        onClick={() => { setSelectedCategory(data); handleOpenAddCategory(); }}
                    >
                        <BsPencil className="text-xl" />
                    </button>

                    <button
                        className="w-12 h-12 bg-red-400 flex justify-center items-center text-white rounded-lg"
                        onClick={() => { setSelectedCategory(data); handleOpenDeleteCategory(); }}
                    >
                        <BsTrash className="text-xl" />
                    </button>
                </div>
            </CardBody>

            <CardFooter>
                <div className="tex-sm capitalize text-gray-500 mb-1">{data.name}</div>
            </CardFooter>
        </Card>
    )
}

export default CategoryCard