import { Typography, Alert } from '@material-tailwind/react';
import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../contexts/CartContext';
import { BsArrowRight, BsCartX } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { API_LINK } from '../../apiConfig';
import axios from 'axios';
import { SuccessOrder } from '../../widgets/modals/orders/SuccessOrder';

const PlaceOrder = () => {
    const navigate = useNavigate();

    const { cart, clearCart, total } = useContext(CartContext);
    const [isActive, setIsActive] = useState(false);

    const [listWilayas, setListWilayas] = useState([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const [openOrderAlert, setOpenOrderAlert] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [wilaya, setWilaya] = useState('');
    const [deliveryType, setDeliveryType] = useState('');
    const [address, setAddress] = useState('');

    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isWilayaEmpty, setIsWilayaEmpty] = useState(false);
    const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);

    const validateInputs = () => {
        setIsFirstnameEmpty(firstname.trim() === '');
        setIsLastnameEmpty(lastname.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsWilayaEmpty(wilaya.trim() === '');
        setIsDeliveryTypeEmpty(deliveryType.trim() === '');
        return !isFirstnameEmpty && !isLastnameEmpty && !isPhoneEmpty && !isWilayaEmpty && !isDeliveryTypeEmpty;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    const transformCartForDatabase = (cart) => {
        return cart.map(({ id, name, imageUrl, price, promotion, amount }) => ({
            quantity: amount,
            unitPrice: promotion ? promotion : price,
            productId: id,
        }));
    };

    useEffect(() => {
        axios.get(`${API_LINK}/wilayas`).then(({ data }) => {
            setListWilayas(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    const addOrderHandler = () => {
        if (validateInputs() && cart.length !== 0) {
            axios.post(`${API_LINK}/orders`, {
                customerName: `${firstname} ${lastname}`,
                customerEmail: email,
                customerPhone: phone,
                shippingType: deliveryType,
                shippingWilaya: wilaya,
                shippingAddress: address,
                orderDetails: transformCartForDatabase(cart)
            }).then(({ data }) => {
                // setOpenOrderAlert(true);
                setOpen(true);
                setTimeout(() => {
                    navigate("/shop");
                    clearCart();
                }, 2000);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            alert('Veuillez remplir tous les champs')
        }
    }



    const showDeliveryPrice = (wilaya, deliveryType) => {
        const selectedWilaya = listWilayas.find(item => item.destination === wilaya);
        if (selectedWilaya) {
            if (deliveryType) {
                if (deliveryType === 'economical') {
                    return selectedWilaya.economical;
                } else if (deliveryType === 'express') {
                    return selectedWilaya.express
                }
            } else {
                return 0;
            }

        } else {
            return 0;
        }
    };

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='w-full p-10 bg-primary mt-[100px] text-white flex sm:flex-row flex-col justify-evenly items-center'>
                <Typography variant="lead" className='sm:block hidden'>
                    <span>MON PANIER</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead">
                    <span className="border-b-2 border-white">VALIDATION DE LA COMMANDE</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead" className='sm:block hidden'>
                    <span>COMMANDE TERMINÉE</span>
                </Typography>
            </div>
{/* 
            <Alert color="green" className='rounded-none' open={openOrderAlert} onClose={() => setOpenOrderAlert(false)}>
                Votre commande a été effectuée avec succès.
            </Alert> */}

            {/* <div className='absolute top-5 right-5 z-50'>
                
            </div> */}

            {cart.length !== 0 ? (<div className='grid sm:grid-cols-2 grid-cols-1 w-full sm:mb-0 mb-20 text-info'>
                <div className='p-8 w-full h-full'>
                    <Typography variant='h4' >
                        Facturation & Expédition
                    </Typography>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Nom | اللقب
                            </Typography>
                            <input type='text' placeholder='Nom | اللقب' onChange={(e) => setLastname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isLastnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Prénom | الاسم
                            </Typography>
                            <input type='text' placeholder='Prénom | الاسم' onChange={(e) => setFirstname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isFirstnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Numéro de téléphone | رقم الهاتف
                            </Typography>
                            <input type='tel' placeholder='Numéro de téléphone | رقم الهاتف' max={13} onChange={(e) => setPhone(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Adresse e-mail | البريد الإلكتروني
                            </Typography>
                            <input type='email' placeholder='Adresse e-mail | البريد الإلكتروني' onChange={(e) => setEmail(e.target.value)} className={`px-2 py-1 border placeholder:font-light border-gray-400`} />
                        </div>


                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Wilaya | الولاية
                            </Typography>
                            {/* <input type='text' placeholder='Wilaya | الولاية' onChange={(e) => setWilaya(e.target.value)} className='px-2 py-1 border border-gray-400 placeholder:font-light' /> */}
                            <select onChange={(e) => setWilaya(e.target.value)} className={`px-2 py-1 border placeholder:font-light bg-white ${isWilayaEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                <option value="" selected disabled>Sélectionner une wilaya</option>
                                {listWilayas.map((wilaya) => (
                                    <option key={wilaya.id} value={wilaya.destination}>{wilaya.id} - {wilaya.destination}</option>
                                ))}
                            </select>
                        </div>


                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                Type de livraison | نوع التوصيل
                            </Typography>
                            <select onChange={(e) => setDeliveryType(e.target.value)} className={`px-2 py-1 border placeholder:font-light bg-white ${isDeliveryTypeEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                <option value="" selected disabled>Sélectionner un type</option>
                                <option value="economical">Économique</option>
                                <option value="express">Express</option>
                            </select>
                        </div>

                        <div className='flex flex-col md:col-span-2 col-span-1'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                L’adresse | عنوان الإقامة  (facultatif)
                            </Typography>
                            <input type='text' placeholder='L’adresse | عنوان الإقامة' onChange={(e) => setAddress(e.target.value)} className='px-2 py-1 border border-gray-400 placeholder:font-light ' />
                        </div>
                    </div>
                </div>
                <div className='w-full p-8 bg-gray-100'>
                    <Typography
                        className='ml-1 mb-2 uppercase text-center'
                        variant='h4'
                    >
                        Votre commande
                    </Typography>
                    <div className='bg-white p-2'>
                        <div className='flex justify-between items-center p-2 border-b-2'>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                Produit
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                Sous-total
                            </Typography>
                        </div>

                        {cart.map((item) => (
                            <div key={item.id} className='flex justify-between items-center p-2 border-b'>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.name} X {item.amount}
                                </Typography>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.promotion ? (item.promotion * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : (item.price * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                                </Typography>
                            </div>
                        ))}

                        <div className='flex justify-between items-center p-2 border-b'>
                            <Typography
                                className='ml-1 uppercase text-center font-light'
                                variant='h6'
                            >
                                Expédition
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center font-light'
                                variant='h6'
                            >
                                {(showDeliveryPrice(wilaya, deliveryType)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                            </Typography>
                        </div>

                        <div className='flex justify-between items-center p-2'>
                            <Typography
                                className='ml-1 text-center capitalize'
                                variant='h6'
                            >
                                Total
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h5'
                            >
                                {(total + showDeliveryPrice(wilaya, deliveryType)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                            </Typography>
                        </div>
                    </div>

                    <Typography
                        className='ml-1 uppercase py-3'
                        variant='paragraph'
                    >
                        Paiement à la livraison
                    </Typography>

                    <button
                        onClick={addOrderHandler}
                        className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium"
                    >
                        Commander
                    </button>

                </div>
            </div>) : (
                <div className='flex flex-col justify-center items-center h-screen  text-info'>
                    <BsCartX size={180} className='text-gray-200 m-5' />
                    <Typography variant='h3' className='text-center'>
                        Votre panier est actuellement vide.
                    </Typography>
                    <div className='m-5'>
                        <Link to={'/'}
                            className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium uppercase"
                        >
                            Retour à la boutique
                        </Link>
                    </div>
                </div>
            )}


            <SuccessOrder open={open} handleOpen={handleOpen} />

        </div>
    )
}

export default PlaceOrder;