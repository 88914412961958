import React from 'react'
import logo from '../../img/logo.png'

function Loading() {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
        <img src={logo} alt='logo' className='h-32 animate-pulse' />
    </div>
  )
}

export default Loading