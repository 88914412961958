import React from "react";
import {
    Dialog,
    DialogBody,
    IconButton
} from "@material-tailwind/react";

import { API_LINK } from "../../../apiConfig";
import { BsX } from "react-icons/bs";
import { Link } from "react-router-dom";

export const ShowAdvertisement = ({ handleOpen, open, advertisement }) => {

    return (
        <>
            <Dialog
                size="md"
                open={open}
                handler={() => { handleOpen() }}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: 0 },
                }}
                className="rounded-none transition-all duration-500"
            >
                <DialogBody className="p-0 m-0 rounded-none">
                    <div className="relative">
                        <img src={advertisement && `${API_LINK}/${advertisement?.imageUrl}`} alt="advertisement" className="w-full" />
                        <div className="absolute top-0 right-0">
                            <IconButton onClick={() => { handleOpen() }} variant="text" className="rounded-none">
                                <BsX size={32} />
                            </IconButton>
                        </div>

                        <div className="absolute inset-x-0 sm:bottom-5 bottom-2 w-full flex justify-center">
                            <Link to="/promotion" className="text-secondary sm:py-3 py-1 sm:px-8 px-4 bg-primary font-medium mt-5 hover:opacity-80">
                                Profitez maintenant
                            </Link>
                        </div>
                    </div>
                </DialogBody>
            </Dialog >
        </>
    );
}