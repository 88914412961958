import React, { useContext, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
} from "@material-tailwind/react";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

export const DeleteCategory = ({ open, handleOpen, setCategories, selectedCategory, setSelectedCategory }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const deleteCategoryHandler = () => {
        setLoading(true);
        if (selectedCategory) {
            axios.delete(`${API_LINK}/categories/${selectedCategory?.id}`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setCategories((products) => products.filter((product) => product.id !== selectedCategory.id));
                closeModalHandler()
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setSelectedCategory(null);
    }

    return (
        <>
            <Dialog
                size="sm"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Supprimer une catégorie
                        </Typography>
                        <div className="w-full flex justify-center">
                            <Typography variant="h6" color="blue-gray" className="w-2/3 text-center">
                                Voulez-vous vraiment supprimer la catégorie <span className="font-bold capitalize">« {selectedCategory?.name} »</span> de votre liste de catégorie
                            </Typography>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-red-400 shadow-none" onClick={() => deleteCategoryHandler()} fullWidth>
                            Supprimer
                        </Button>
                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}