import { useLocation, Link } from "react-router-dom";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Breadcrumbs,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import {
  Bars3Icon,
  ArrowRightStartOnRectangleIcon,
  Cog6ToothIcon
} from "@heroicons/react/24/solid";

import { KeyIcon } from "@heroicons/react/24/outline";

import {
  useMaterialTailwindController,
  setOpenSidenav,
} from "../../contexts/MaterialContext";

import profile from '../../img/profile.jpg'

import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

export function DashboardNavbar({ handleOpen }) {
  const { authTokens, logout } = useContext(AuthContext)

  const [controller, dispatch] = useMaterialTailwindController();
  const { openSidenav } = controller;
  const { pathname } = useLocation();
  const [layout, page] = pathname.split("/").filter((el) => el !== "");

  return (
    <Navbar
      color={"transparent"}
      className={`rounded-xl transition-all "sticky top-4 z-40 px-0 py-3`}
      fullWidth
    >
      <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div className="capitalize">
          <Breadcrumbs
            className={`bg-transparent p-0 transition-all`}
          >
            <Link to={`/${layout}`}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal opacity-50 transition-all hover:text-[#E1B6A5] hover:opacity-100"
              >
                {layout}
              </Typography>
            </Link>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {page}
            </Typography>
          </Breadcrumbs>
          <Typography variant="h6" color="blue-gray">
            {page}
          </Typography>
        </div>
        <div className="flex items-center">
          <div className="mr-auto md:mr-4 md:w-56"></div>
          <IconButton
            variant="text"
            color="blue-gray"
            className="grid lg:hidden"
            onClick={() => setOpenSidenav(dispatch, !openSidenav)}
          >
            <Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
          </IconButton>

          {/* <Button
            variant="text"
            color="blue-gray"
            className="flex items-center"
            onClick={() => logout()}
          >
            <ArrowRightStartOnRectangleIcon strokeWidth={3} className="h-5 w-5 text-blue-gray-500" />
            <Typography
              variant="small"
              className="font-medium lg:block hidden ml-4"
            >
              Déconnexion
            </Typography>
          </Button> */}

          <Menu>
            <MenuHandler>
              <Button
                variant="text"
                color="blue-gray"
                className="flex items-center"
              >
                <Cog6ToothIcon strokeWidth={3} className="h-5 w-5 text-blue-gray-500" />
                <Typography
                  variant="small"
                  className="font-medium lg:block hidden ml-4"
                >
                  Paramètre
                </Typography>
              </Button>
            </MenuHandler>
            <MenuList className="w-max border-0">
              <MenuItem className="flex items-center gap-3" disabled>
                <Avatar
                  src={profile}
                  alt="item-1"
                  size="sm"
                  variant="circular"
                />
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-normal"
                  >
                    <strong className="capitalize">{authTokens.user?.lastname} {authTokens.user?.firstname}</strong>
                  </Typography>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center gap-1 text-xs font-normal opacity-60"
                  >
                    {authTokens.user?.email}
                  </Typography>
                </div>
              </MenuItem>
              <MenuItem className="flex items-center gap-4" onClick={() => handleOpen()}>
                <div className="grid h-9 w-9 place-items-center rounded-full bg-primary">
                  <KeyIcon className="h-4 w-4 text-white" />
                </div>
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-normal"
                  >
                    Changer mot de passe
                  </Typography>
                </div>
              </MenuItem>
              <MenuItem className="flex items-center gap-4" onClick={() => logout()}>
                <div className="grid h-9 w-9 place-items-center rounded-full bg-red-400">
                  <ArrowRightStartOnRectangleIcon className="h-4 w-4 text-white" />
                </div>
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-normal"
                  >
                    Déconnexion
                  </Typography>
                </div>
              </MenuItem>
            </MenuList>
          </Menu>

        </div>
      </div>
    </Navbar>
  );
}


export default DashboardNavbar;
