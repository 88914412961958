import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";

import SidebarProvider from "./contexts/SidebarContext";
import CartProvider from "./contexts/CartContext";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { MaterialTailwindControllerProvider } from "./contexts/MaterialContext";
import { LoadingProvider } from './contexts/LoadingContext';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <SidebarProvider>
        <CartProvider>
          <React.StrictMode>
            <ThemeProvider>
              <MaterialTailwindControllerProvider>
                <LoadingProvider>
                  <App />
                </LoadingProvider>
              </MaterialTailwindControllerProvider>
            </ThemeProvider>
          </React.StrictMode>
        </CartProvider>
      </SidebarProvider>
    </AuthProvider>
  </BrowserRouter>
);
