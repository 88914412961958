import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";


const PrivateRoute = () => {
    const location = useLocation();
    const { isLoggedIn } = useContext(AuthContext)
    return isLoggedIn ? <Outlet /> : <Navigate to="/login" replace state={{from: location}} />
};
export default PrivateRoute;