import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_LINK } from '../../apiConfig';
import Product from '../../components/Product';
import { Typography } from '@material-tailwind/react';

import { BsSearch } from "react-icons/bs";
import { NavLink, useLocation } from 'react-router-dom';


const CandleReed = () => {
    const location = useLocation();


    const [isActive, setIsActive] = useState(false);
    const [products, setProducts] = useState([]);
    const [sortOrder, setSortOrder] = useState();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    useEffect(() => {
        axios.get(`${API_LINK}/products/search?category=candle-reed&type=undefined&specification=undefined&priceOrder=${sortOrder}`).then(({ data }) => {
            setProducts(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [sortOrder]);


    return (

        <div className='h-screen'>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all `}
            ></div>
            <div className='sm:mt-[110px] mt-[96px] text-info'>
                {!location.pathname.startsWith("/product/") && <div className='bg-secondary w-full flex justify-center rounded-none mt-5 p-5'>
                    <div className='flex sm:flex-row flex-col items-center text-primary'>
                        <NavLink to="/diffuser" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Diffuseurs
                                </Typography>
                            )}
                        </NavLink>
                        <NavLink to="/parfum" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Parfums
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/candle-reed" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Candles & Reed
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/promotion" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Promotions
                                </Typography>
                            )}
                        </NavLink>
                    </div>
                </div>}

                <div className={`bg-white flex justify-center items-center h-[60vh] w-full transition-all`}>
                    <h1 className='text-5xl font-secondary text-primary'>
                        Bientôt
                    </h1>
                </div>

            </div>
        </div>

    )
}

export default CandleReed