import logo1 from '../../img/home/Logos references/logo1.png';
import logo2 from '../../img/home/Logos references/logo2.png';
import logo3 from '../../img/home/Logos references/logo3.png';
import logo4 from '../../img/home/Logos references/logo4.png';
import logo5 from '../../img/home/Logos references/logo5.png';
import logo6 from '../../img/home/Logos references/logo6.png';
import logo7 from '../../img/home/Logos references/logo7.png';
import logo8 from '../../img/home/Logos references/logo8.png';
import logo9 from '../../img/home/Logos references/logo9.png';
import logo10 from '../../img/home/Logos references/logo10.png';
import logo11 from '../../img/home/Logos references/logo11.png';
import logo12 from '../../img/home/Logos references/logo12.png';
import logo13 from '../../img/home/Logos references/logo13.png';
import logo14 from '../../img/home/Logos references/logo14.png';
import logo15 from '../../img/home/Logos references/logo15.png';
import logo16 from '../../img/home/Logos references/logo16.png';
import logo17 from '../../img/home/Logos references/logo17.png';

const dataMarks = [
    { id: 1, text: "logo1", image: logo1 },
    { id: 2, text: "logo2", image: logo2 },
    { id: 3, text: "logo3", image: logo3 },
    { id: 4, text: "logo4", image: logo4 },
    { id: 5, text: "logo5", image: logo5 },
    { id: 6, text: "logo6", image: logo6 },
    { id: 7, text: "logo7", image: logo7 },
    { id: 8, text: "logo8", image: logo8 },
    { id: 9, text: "logo9", image: logo9 },
    { id: 10, text: "logo10", image: logo10 },
    { id: 11, text: "logo10", image: logo11 },
    { id: 12, text: "logo10", image: logo12 },
    { id: 13, text: "logo10", image: logo13 },
    { id: 14, text: "logo10", image: logo14 },
    { id: 15, text: "logo10", image: logo15 },
    { id: 16, text: "logo10", image: logo16 },
    { id: 17, text: "logo10", image: logo17 }
];

export default dataMarks;
