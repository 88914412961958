import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Select,
    Option,
    Spinner,
} from "@material-tailwind/react";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

export const UpdateUser = ({ open, handleOpen, setUsers, selectedUser, setSelectedUser }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');


    useEffect(() => {
        if (open) {
            if (selectedUser) {
                setFirstname(selectedUser.firstname || '');
                setLastname(selectedUser.lastname || '');
                setEmail(selectedUser.email || '');
                setRole(selectedUser.role || '');
            }
        }
    }, [open, selectedUser]);


    const updateUserHandler = () => {
        setLoading(true);
        if (firstname && lastname && email && role) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            if (selectedUser) {
                axios.patch(`${API_LINK}/users/${selectedUser?.id}`, {
                    firstname,
                    lastname,
                    email,
                    role
                }, requestConfig).then(({ data }) => {
                    setUsers(users => users.map(user => user.id === data.id ? data : user));
                    closeModalHandler();
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            }

        } else {
            alert("Veuillez remplir tous les champs");
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setFirstname('');
        setLastname('');
        setEmail('');
        setRole('');

        setSelectedUser();
    }


    return (
        <>
            {<Dialog
                size="md"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            {selectedUser ? "Modifier l'utilisateur" : ""}
                        </Typography>

                        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4  w-full">
                            <Input label="Nom" type="text" value={lastname} disabled onChange={(e) => setLastname(e.target.value)} />
                            <Input label="Prénom" type="text" value={firstname} disabled onChange={(e) => setFirstname(e.target.value)} />
                            <Input label="Adresse email" type="email" value={email} disabled onChange={(e) => setEmail(e.target.value)} />
                            <Select label="Rôle" value={role} onChange={(e) => setRole(e)}>
                                <Option value="admin">Administrateur</Option>
                                <Option value="stock">Gestionnaire de stock</Option>
                                <Option value="delivery">Gestionnaire de livraison</Option>
                            </Select>

                        </div>

                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-[#E1B6A5] shadow-none" onClick={() => updateUserHandler()} fullWidth>
                            {selectedUser ? "Modifier" : ""}
                        </Button>

                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>}
        </>
    );
}