import React, { useEffect, useState } from "react";
import {
    Dialog,
    Card,
    CardBody,
} from "@material-tailwind/react";
import YouTube from 'react-youtube';


export const ShowVideo = ({ open, handleOpen, video }) => {

    const [width, setWidth] = useState("100%");

    useEffect(() => {
        const handleResize = () => {
            // Mettre à jour la largeur de la vidéo en fonction de la largeur de la fenêtre
            const newWidth = window.innerWidth > 640 ? "640px" : "100%";
            setWidth(newWidth);
        };

        // Mettre à jour la largeur initiale de la vidéo
        handleResize();

        // Ajouter un écouteur d'événement pour redimensionner la fenêtre
        window.addEventListener("resize", handleResize);

        // Nettoyer l'écouteur d'événement lors du démontage du composant
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getYouTubeVideoId = (url) => {
        const regExp =
            /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);
        return match && match[1];
    };

    return (
        video && <>
            <Dialog
                size="sm"
                open={open}
                handler={handleOpen}
                className="bg-transparent shadow-none"
            >
                <Card className="mx-auto w-full rounded-none">
                    <CardBody className="p-0">
                        <div style={{ width: width }}>
                            <YouTube opts={{width: width}} videoId={getYouTubeVideoId(video)} />
                        </div>
                    </CardBody>
                </Card>
            </Dialog>
        </>
    );
}